import * as React from "react";
import { EGDSLayoutGridItem } from "@egds/react-core/layout-grid";
import { observer } from "mobx-react";
import { CarTypeInformation } from "src/stores/wizard/config/staticConfigs/car/typings";
import { formatText } from "bernie-l10n/dist/localization";
import { locKeys } from "../l10n";
import { CarTypeDropdownProps } from "../../typings";
import {
  EGDSMenu,
  EGDSMenuTrigger,
  EGDSMenuContainer,
  EGDSMenuList,
  MenuItemConfig,
  PositionType,
} from "@egds/react-core/menu";
import { EGDSLink } from "@egds/react-core/link";
import { EGDSIcon } from "@egds/react-core/icons";
import { CarTypes } from "src/components/flexComponents/WizardCarPWA/components/vendorOptions/CarTypes";

export const CarTypeDropdown = observer(({ carWizardState }: CarTypeDropdownProps) => {
  const carFromConfig: CarTypeInformation[] = carWizardState.config.cars;
  const cars: CarTypeInformation[] = Object.values(carFromConfig).filter(
    ({ includeInDropdown }: CarTypeInformation) => includeInDropdown
  );

  if (!cars || !cars[0] || !Array.isArray(cars)) return null;

  const { carType } = carWizardState;
  const defaultCar = Object.values(cars).filter(({ locKey }: CarTypeInformation) => carType === formatText(locKey));
  const carInitialVal = defaultCar[0] || cars[0];
  const currentCar = carInitialVal ? formatText(carInitialVal.locKey) : formatText(cars[0].locKey);
  const searchValue: CarTypeInformation[] = Object.values(carFromConfig).filter(
    ({ locKey }: CarTypeInformation) => formatText(locKey) === carWizardState.carType
  );

  const searchKey = searchValue[0]
    ? `["${searchValue[0].carCode.toLowerCase()}"]`
    : `["${cars[0].carCode.toLowerCase()}"]`;

  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const linkRef: React.RefObject<HTMLAnchorElement> = React.createRef();

  const handleMenuTriggerClick = (event: React.MouseEvent) => {
    event.preventDefault();
    setIsMenuOpen(!isMenuOpen);
  };

  const handleCarTypeClick = (carCodeInput: string) => () => {
    const carVal = Object.values(cars).filter(({ carCode }: CarTypeInformation) => carCode === carCodeInput);
    carWizardState.carType = carCodeInput === CarTypes.ALL ? "" : formatText(carVal[0].locKey);
    setIsMenuOpen(false);
    linkRef.current?.focus();
  };

  return (
    <EGDSLayoutGridItem>
      <div>
        <EGDSMenu id="car-type-list" isOpen={isMenuOpen} onTriggerClick={handleMenuTriggerClick}>
          <EGDSMenuTrigger>
            <EGDSLink id="car-type-list-trigger" data-testid="car-type-list-trigger">
              <a
                href=""
                role="button"
                aria-label={`${formatText(locKeys.carType)} ${carType !== "" ? currentCar : ""}`}
                ref={linkRef}
              >
                {currentCar}
                <EGDSIcon name="expand_more" />
              </a>
            </EGDSLink>
          </EGDSMenuTrigger>

          <EGDSMenuContainer position={PositionType.LEFT}>
            <EGDSMenuList
              items={cars.map(
                ({ locKey, carCode }): MenuItemConfig => ({
                  attributes: {
                    role: "menuItem",
                    tabindex: 0,
                  },
                  className: "uitk-list-item",
                  label: ` ${carCode !== CarTypes.ALL ? formatText(locKey) : ""}`,
                  href: "#",
                  typeAnchor: true,
                  onClick: handleCarTypeClick(carCode),
                })
              )}
            />
          </EGDSMenuContainer>
        </EGDSMenu>
        {carWizardState.carType !== "" && <input type="hidden" data-testid="selCC" name="selCC" value={searchKey} />}
      </div>
    </EGDSLayoutGridItem>
  );
});
