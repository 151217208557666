import * as React from "react";
import { EGDSSelect } from "@egds/react-core/input";
import { BrandDropdownProps } from "components/flexComponents/WizardCarPWA/typings";
import { EGDSLayoutGridItem } from "@egds/react-core/layout-grid";
import { observer } from "mobx-react";
import { withStores } from "stores";
import { CarVendorInformation } from "src/stores/wizard/config/staticConfigs/car/typings";
import { useLocalization } from "@shared-ui/localization-context";
import { CarVendorWithExperimentInformationMapOverrides } from "src/stores/wizard/config/overrideConfigBySiteId/typings";
import { isVariantEnabled } from "src/components/utility/experiment";

export const BrandDropdown = withStores("context")(
  observer((props: BrandDropdownProps) => {
    const vendorsFromConfig: CarVendorInformation[] = props.lobState.config.vendors;
    const vendorsExperimentMap: CarVendorWithExperimentInformationMapOverrides[] =
      props.lobState.config.preferredVendors ?? [];

    let vendorList: CarVendorInformation[] = Object.values(vendorsFromConfig).filter((vendor: CarVendorInformation) => {
      return vendor.includeInDropdown;
    });
    vendorsExperimentMap.forEach((vendorExperiment) => {
      const isEnabled = isVariantEnabled(props.context, vendorExperiment.experimentCode, vendorExperiment.bucket);
      if (isEnabled) {
        var vendorsFromExperiment = vendorExperiment.vendors;
        vendorList = Object.values(vendorList).filter((vendor: CarVendorInformation) => {
          return vendorsFromExperiment[vendor.vendorCode]?.includeInDropdown ?? vendor.includeInDropdown;
        });
      }
    });
    const { formatText } = useLocalization();

    return (
      <EGDSLayoutGridItem colSpan={props.colSpan}>
        <div>
          <EGDSSelect
            label={props.label}
            aria-label={props.label}
            onChange={props.lobState.setPreferredBrand}
            value={props.lobState.preferredBrand}
          >
            {vendorList.map((opt) => (
              <option value={opt.vendorCode} key={opt.vendorCode}>
                {formatText(opt.locKey)}
              </option>
            ))}
          </EGDSSelect>
          <input type="hidden" name="vend" value={props.lobState.preferredBrand} />
        </div>
      </EGDSLayoutGridItem>
    );
  })
);
