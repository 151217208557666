import * as React from "react";
import { EGDSSelect } from "@egds/react-core/input";
import { CouponCodeDropdownProps } from "components/flexComponents/WizardCarPWA/typings";
import { locKeys } from "components/flexComponents/WizardCarPWA/components/l10n";
import { EGDSLayoutGridItem } from "@egds/react-core/layout-grid";
import { observer } from "mobx-react";
import { useLocalization } from "@shared-ui/localization-context";
import { CarVendorInformation } from "src/stores/wizard/config/staticConfigs/car/typings";
import { couponCodeTypes } from "./CouponCodeTypes";

export const CouponCodeDropdown = observer((props: CouponCodeDropdownProps) => {
  const { formatText } = useLocalization();
  const { noCode, corporateCode, specialCode } = locKeys;
  const { NO_CODE, CORPORATE_CODE, SPECIAL_CODE } = couponCodeTypes;
  const noReferenceVendor: CarVendorInformation = props.carWizardState.config.vendors.NO_PREFERENCE;

  if (!props.carWizardState.preferredBrand || props.carWizardState.preferredBrand === noReferenceVendor.vendorCode) {
    return null;
  }

  return (
    <EGDSLayoutGridItem colSpan={props.colSpan}>
      <div>
        <EGDSSelect
          aria-label={props.label}
          label={props.label}
          onChange={props.carWizardState.updateCouponCodeType}
          value={props.carWizardState.carCouponCodeType}
        >
          <option key={NO_CODE} value={NO_CODE}>
            {formatText(noCode)}
          </option>
          <option key={CORPORATE_CODE} value={CORPORATE_CODE}>
            {formatText(corporateCode)}
          </option>
          <option key={SPECIAL_CODE} value={SPECIAL_CODE}>
            {formatText(specialCode)}
          </option>
        </EGDSSelect>
      </div>
    </EGDSLayoutGridItem>
  );
});
